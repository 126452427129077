import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from '../Assets/logo.png';
import './Navbar.css'

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 700; // Change 100 to the scroll distance you prefer
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [scrolled]);

    return(
        <div className={`navbar_main ${scrolled ? 'scrolled' : ''}`}>
            <div className="nav-container">
                <div className="logo">
                    <Link to = "/"><img src = {Logo} alt="OZlooms-Logo" /></Link>
                </div>
                <div className="nav-menu">
                    <ul className = {menuOpen ? "open" : ""}>
                        <li onClick={() => setMenuOpen(!menuOpen)}><NavLink style = { { textDecoration : 'none' } } to = '/'>HOME</NavLink></li>
                        <li onClick={() => setMenuOpen(!menuOpen)}><NavLink style = { { textDecoration : 'none' } } to = '/Services'>SERVICES</NavLink></li>
                        <li onClick={() => setMenuOpen(!menuOpen)}><NavLink style = { { textDecoration : 'none' } } to = '/about'>ABOUT US</NavLink></li>
                        <li onClick={() => setMenuOpen(!menuOpen)}><NavLink style = { { textDecoration : 'none' } } to = '/contact'>CONTACT US</NavLink></li>   
                    </ul>
                </div>
            </div>
            <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
                <i className="fa-solid fa-bars"></i>
            </div>
        </div>
    );
};

export default Navbar;