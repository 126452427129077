import React from 'react'
import landscape_banner from "../Components/Assets/TshirtBanner.jpg"
import potrait_banner from "../Components/Assets/TshirtBanner_Mobile.jpg"
import { Link } from 'react-router-dom'
import Hero from '../Components/Hero/Hero'

const Tshirt = () => {
  return (
    <div>
        <Hero
         landscape_image = {landscape_banner} potrait_image = {potrait_banner}/>
        <div className="productContent">
            {/*Redirect button*/}
            <div className="button_container">
                <button>Get your own custom Tshirts!</button>
            </div>

            {/*On-Court*/}
            <div className="content_heading">
                <span>PRINTED</span>
            </div>
            <hr />
            <div className="content_tagline">
                <div className="bar"></div>
                <span>Add your brand, club or company logos</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Screen Printed, Direct to Garment, Cut, Color, etc</span></li>
                    <li><span>Sampling prior to production</span></li>
                    <li><span>Quick Turnaround time</span></li>
                    <li><span>No Minimum Order Quanity</span></li>
                    <li><span>Unlimited options with design process</span></li>
                </ul>
            </div>
            <div className="content_sub_heading">
                <span>Good for</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Clothing brands</span></li>
                    <li><span>Uniforms</span></li>
                    <li><span>Sports Clubs</span></li>
                    <li><span>Anything else!</span></li>
                </ul>
            </div>
            <div className="redirect_button">
                <span>Your logos, your choice</span>
                <Link to="/contact"><button>Get in Touch</button></Link>
            </div>

            {/*Training*/}
            <div className="content_heading">
                <span>SUBLIMATED</span>
            </div>
            <hr />
            <div className="content_tagline">
                <div className="bar"></div>
                <span>Unlimited colors, unlimited design</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Sampling prior to production</span></li>
                    <li><span>Quick Turnaround time</span></li>
                    <li><span>No Minimum Order Quanity</span></li>
                    <li><span>Unlimited options with design process</span></li>
                </ul>
            </div>
            <div className="content_sub_heading">
                <span>Good for</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Anyone who loves a lot oc color and design</span></li>
                </ul>
            </div>
            <div className="redirect_button">
                <span>Unlimited design with sublimation</span>
                <Link to="/contact"><button>Get in Touch</button></Link>
            </div>
            <div className="content_heading">
                <span>WOMEN</span>
            </div>
            <hr />
            <div className="content_tagline">
                <div className="bar"></div>
                <span>Add your brand, club or company logos</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Sampling prior to production</span></li>
                    <li><span>Quick Turnaround time</span></li>
                    <li><span>No minimum Order Quanity</span></li>
                    <li><span>Unlimited options with design process</span></li>
                </ul>
            </div>
            <div className="content_sub_heading">
                <span>Good For</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Clothing brands</span></li>
                    <li><span>Uniforms</span></li>
                    <li><span>Sports Clubs</span></li>
                    <li><span>Anything else!</span></li>
                </ul>
            </div>
            <div className="redirect_button">
                <span>Great pricing on women's tees!</span>
                <Link to="/contact"><button>Get in Touch</button></Link>
            </div>
            <div className="content_heading">
                <span>POCKET</span>
            </div>
            <hr />
            <div className="content_tagline">
                <div className="bar"></div>
                <span>Add a Pocket to your tees</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Sampling prior to production</span></li>
                    <li><span>Quick Turnaround time</span></li>
                    <li><span>No Minimum Order Quanity</span></li>
                    <li><span>Unlimited design options</span></li>
                </ul>
            </div>
            <div className="content_sub_heading">
                <span>Good For</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Clothing brands</span></li>
                    <li><span>Uniforms</span></li>
                    <li><span>Sports Clubs</span></li>
                    <li><span>Anything else!</span></li>
                </ul>
            </div>
            <div className="redirect_button">
                <span>Geyt a quote on pocket tees today!</span>
                <Link to="/contact"><button>Get in Touch</button></Link>
            </div>
            <div className="content_heading">
                <span>LONG SLEEVE</span>
            </div>
            <hr />
            <div className="content_tagline">
                <div className="bar"></div>
                <span>Winter tees!</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Sampling prior to production</span></li>
                    <li><span>Quick Turnaround time</span></li>
                    <li><span>No minimum Order Quanity</span></li>
                    <li><span>Unlimited options with design process</span></li>
                </ul>
            </div>
            <div className="content_sub_heading">
                <span>Good For</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Clothing brands</span></li>
                    <li><span>Uniforms</span></li>
                    <li><span>Sports Clubs</span></li>
                    <li><span>Anything else!</span></li>
                </ul>
            </div>
            <div className="redirect_button">
                <span>Fully customised balls</span>
                <Link to="/contact"><button>Get in Touch</button></Link>
            </div>
        </div>
    </div>
  )
}

export default Tshirt
