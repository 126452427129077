import React from "react";
import Hero from "../Components/Hero/Hero";
import about_banner from "../Components/Assets/about_banner.jpg"
import about_banner_mobile from "../Components/Assets/about_banner_mobile.jpg"
import About_Content from "../Components/About_Content/About_Content";
import About_Gallery from "../Components/About_Gallery/About_Gallery";
import Team from "../Components/Team/Team";
import "../Pages/styles/Services.css"
import AboutTagline from "../Components/AboutTagline/AboutTagline";
import Ourstory from "../Components/OurStory/OurStory";
import Customization from "../Components/Customization/Customization";
import AboutSustain from "../Components/AboutSustain/AboutSustain";
import Promises from "../Components/Promises/Promises";
import Location from "../Components/Location/Location";
import Advantages from "../Components/Advantages/Advantages";
import Capacity from "../Components/Capacity/Capacity";

const About = () => {
    return(
        <div className="about">
            <AboutTagline/>
            <Ourstory/>
            <Customization/>
            <AboutSustain/>
            <Promises/>
            <Location/>
            <Advantages/>
            <Capacity/>
        </div>
    )
}

// const About = () => {
//     // return(
//     //     <div className="under-construction">
//     //         <div className="container">
//     //             <h1>Page Under Construction</h1>
//     //             <p>We're working hard to bring you something amazing! Stay tuned.</p>
//     //             <div className="loader"></div>
//     //         </div>
//     //     </div>
//     // );
// };

export default About;