import React from 'react'
import './Accessories.css'
import Accessories1 from '../Assets/accessories1.png';
import Accessories2 from '../Assets/accessories2.png'

const Accessories = () => {
  return (
    <div className='accerrories'>
      <div className="accessoriesHeadeing">
        <span className="heading">Accessories</span>
        <span className="tagline">Perfect finishing touch: Custom accessories for every style</span>
      </div>
      <div className="accessoriesContent">
        <div className="card">
            <div className="cardImg">
                <img src={Accessories1} alt="" />
            </div>
            <div className="cardContent">
                <span className='cardHeading'>Custom Accessories</span>
                <span className="cardTagline">Handmade ties, pocket squares, scarves, and other accessories to complement your custom garments.</span>                
            </div>
        </div>
        <div className="card">
            <div className="cardImg">
                <img src={Accessories2} alt="" />
            </div>
            <div className="cardContent">
                <span className='cardHeading'>Personalized Embroidery</span>
                <span className="cardTagline">Custom embroidery services for monograms, logos, and personalized details.</span>                
            </div>
        </div>
      </div>
    </div>
  )
}

export default Accessories
