import React from 'react'
import "./AboutTagline.css"

const AboutTagline = () => {
  return (
    <div className='aboutTagline'>
      <div className="aboutContent">
      <h2>About <span>Us</span></h2>
      <p>Welcome to OZLOOMS, where your style meets our craftsmanship.</p>
      <p>At OZLOOMS, we believe that every garment should tell a story - your story. We are dedicated to creating custom clothing that reflects your unique personality and preferences, ensuring you stand out in a way that is truly your own. Whether you're looking for a Custom sports garments, Teamwear, bespoke suit, a one-of-a-kind dress, or personalized casual wear, our team of experienced designers, fabric consultants and tailors is here to bring your vision to life.</p>
      <button>Get a quote</button>
      </div>
    </div>
  )
}

export default AboutTagline