import React from "react";
import './styles/Services.css';
import Business_Consultation from '../Components/Assets/Bussiness_consultation.jpg';
import fashion_consultation from '../Components/Assets/fashion-consultation.jpg'
import fabric_consultation from '../Components/Assets/fabric-consultation.jpg'
import Hero from "../Components/Hero/Hero";
import banner from "../Components/Assets/Our_Services.png";
import { Link } from "react-router-dom";
import ServicesTagline from "../Components/servicesTagline/ServicesTagline";
import CustomTailoring from "../Components/CustomTailoring/CustomTailoring";
import CustomPrinting from "../Components/CustomPrinting/CustomPrinting";
import WhyPrinting from "../Components/WhyPrinting/WhyPrinting";
import CorporateServices from "../Components/CorporateServices/CorporateServices";
import PrivateLabel from "../Components/PrivateLabel/PrivateLabel";
import Consultation from "../Components/Consultation/Consultation";
import SustainableFashion from "../Components/SustainableFashion/SustainableFashion";
import Accessories from "../Components/Accessories/Accessories";


const Services = () => {
    return(
        <div className="services">
            <ServicesTagline/>
            <CustomTailoring/>
            <CustomPrinting/>
            <WhyPrinting/>
            <CorporateServices/>
            <PrivateLabel/>
            <Consultation/>
            <SustainableFashion/>
            <Accessories/>
        </div>
    )
}

// const Services = () => {
//     return(
//         <div className="under-construction">
//             <div className="container">
//                 <h1>Page Under Construction</h1>
//                 <p>We're working hard to bring you something amazing! Stay tuned.</p>
//                 <div className="loader"></div>
//             </div>
//         </div>
//     );
// };

export default Services;