import React from 'react'
import "./Consultation.css"

const Consultation = () => {
  return (
    <div className='consultation'>
        <div className="consultationHeading">
            <span className="consultationTitle">Design and Consultation</span>
            <span className="consultationTagline">Tailored to your style</span>
        </div>
        <div className="consultationContent">
            <div className="card consultationCard1">
                <div className="cardInfo">
                    <span className="cardInfoHeading">Personal design consultation</span>
                    <span className='cardInfoTagline'>One-on-one consultations with our experienced designers to create unique, custom pieces tailored to your specific requirements.</span>
                    <button>Book an appointment</button>
                </div>
            </div>
            <div className="card consultationCard2">
                <div className="cardInfo">
                    <span className="cardInfoHeading">Style Advisory</span>
                    <span className='cardInfoTagline'>Expert advice on fabric selection, styling, and fit to ensure you achieve the perfect look.</span>
                    <button>Book an appointment</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Consultation
