import React from "react";
import contact_banner from "../Components/Assets/contactImage.jpg";
import contact_banner_mobile from "../Components/Assets/contactImage_mobile.jpg"
import ContactForm from "../Components/ContactForm/ContactForm";
import Hero from "../Components/Hero/Hero";

const Contact = () => {
    return (
        <div className="Contact">
            <Hero landscape_image = {contact_banner} potrait_image = {contact_banner_mobile} style={{ height: '100vh', objectFit: "cover" }}/>
            <ContactForm />
        </div>
    );
};

export default Contact;