import React from "react";
import {Link} from "react-router-dom"
import './Services.css';
import Bussiness from '../Assets/Bussiness.jpg';
import Fabric from '../Assets/fabric.jpg';
import Fashion from '../Assets/fashion.jpg'

const Services = () => {
    return(
        <div className="services">
            <div className="services_heading">
                <div className="services_title">
                    <h1>OUR <span>SERVICES</span></h1>
                </div>
                <hr className="mainHr"/>
                <div className="services_tagline">
                    <span>Ozlooms: Your gateway to excellence in fashion, textiles & business consultation.</span>
                </div>
            </div>
            <div className="services_card_container">
                <div className="card_wrapper">
                    <div className="card">
                        <div className="card_img">
                            <img src={Bussiness} alt="" />
                        </div>
                        <div className="card_content">
                            <div className="card_heading">
                                <span>Micro Business Consultation</span>
                            </div>
                            <hr />
                            <div className="card_text">
                                <span>Guiding Micro Businesses Towards Growth and Sustainability. Tailored Consultation for Your Unique Needs. Unlock Your Business Potential with Expert Advice. Practical Solutions for Every Stage of Your Business Journey. Let's Build Success Together, One Step at a Time.</span>
                            </div>
                        </div>
                    </div>
                    <Link to="/services"><button>Read More</button></Link>
                </div>
                <div className="card_wrapper">
                    <div className="card">
                        <div className="card_img">
                            <img src={Fashion} alt="" />
                        </div>
                        <div className="card_content">
                            <div className="card_heading">
                                <span>High-end Fashion Consultation</span>
                            </div>
                            <hr />
                            <div className="card_text">
                                <span>Refine Your Style, Define Your Presence. Experience Exclusive High-End Fashion Consultation Tailored to Your Unique Taste. Unlock the Secrets of Luxury and Elegance with Expert Guidance. Step into a World of Confidence and Sophistication. Your Journey to Fashion Excellence Starts Here</span>
                            </div>
                        </div>
                    </div>
                    <Link to="/services"><button>Read More</button></Link>
                </div>
                <div className="card_wrapper">
                    <div className="card">
                        <div className="card_img">
                            <img src={Fabric} alt="" />
                        </div>
                        <div className="card_content">
                            <div className="card_heading">
                                <span>Fabric & Sourcing Consultaion</span>
                            </div>
                            <hr />
                            <div className="card_text">
                                <span>Your Source for Fabric Excellence. Expert Guidance in Fabric Selection and Sourcing. Tailored Solutions for Your Textile Needs. Unlock the Potential of Quality Materials. Let Us Navigate the World of Fabrics Together. Elevate Your Creations with Our Sourcing Expertise. Your Partner in Textile Success.</span>
                            </div>
                        </div>
                    </div>
                    <Link to="/services"><button>Read More</button></Link>
                </div>
            </div>
        </div>
    );
};

export default Services;