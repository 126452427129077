import React from 'react'
import "./PrivateLabel.css"

const PrivateLabel = () => {
  return (
    <div className='privateLabel'>
      <div className="privateLabelHeading">
        <span className='privateLabelTitle'>Private label and wholesale production</span>
        <span className="privateLabelTagline">Your brand, our exptertise: Premium private label and wholesale garment production</span>
      </div>
      <div className="privateContent">
        <div className="card privateCard1">
            <div className="cardInfo">
                <span className="cardTitle">Private Label Manufacturing</span>
                <span className='cardTagline'>Partner with us to create your own line of clothing, from initial design and prototyping to final production and quality control.</span>
                <button>Place Order</button>
            </div>
        </div>
        <div className="card privateCard2">
            <div className="cardInfo">
                <span className="cardTitle">Wholesale Garments</span>
                <span className='cardTagline'>Bulk production of high-quality garments for retailers and fashion brands, with flexible order quantities and competitive pricing.</span>
                <button>Place Order</button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default PrivateLabel
