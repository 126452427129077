import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Products from "../Components/products/Products";
import Services from "../Components/Services/Services";
import Client from "../Components/Clients/Clients";
import CarouselPage from "../Components/Banner_Carousel/BannerCarousel";


const Home = () => {
    return(
        <div>
            <CarouselPage/>
            <Products/>
            <Services/>
            <Client/>
        </div>
    );
};

export default Home;