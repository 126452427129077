import React from 'react'
import landscape_banner from "../Components/Assets/soccerBanner.jpg"
import potrait_banner from "../Components/Assets/soccerBanner_Mobile.jpg"
import { Link } from 'react-router-dom'
import Hero from '../Components/Hero/Hero'

const Soccer = () => {
  return (
    <div>
        <Hero landscape_image = {landscape_banner} potrait_image = {potrait_banner}/>
        <div className="productContent">
            {/*Redirect button*/}
            <div className="button_container">
                <button>Get your own custom Basketball jerseys!</button>
            </div>

            {/*On-Court*/}
            <div className="content_heading">
                <span>MATCH DAY KITS</span>
            </div>
            <hr />
            <div className="content_tagline">
                <div className="bar"></div>
                <span>It's more than a game</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Sampling prior to production</span></li>
                    <li><span>Quick Turnaround time</span></li>
                    <li><span>No Minimum Order Quanity</span></li>
                    <li><span>Unlimited options with design process</span></li>
                    <li><span>Personalised names and numbers</span></li>
                </ul>
            </div>
            <div className="content_sub_heading">
                <span>Unique Features</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>All neck line options available</span></li>
                    <li><span>Short and long sleeve option</span></li>
                    <li><span>Short come with elastic waist and drawstring</span></li>
                </ul>
            </div>
            <div className="redirect_button">
                <span>Transform your club kits!</span>
                <Link to="/contact"><button>Get in Touch</button></Link>
            </div>

            {/*Training*/}
            <div className="content_heading">
                <span>TRAINING & WARM UP</span>
            </div>
            <hr />
            <div className="content_tagline">
                <div className="bar"></div>
                <span>Train like a champion</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Sampling prior to production</span></li>
                    <li><span>Quick Turnaround time</span></li>
                    <li><span>No Minimum Order Quanity</span></li>
                    <li><span>Unlimited options with design process</span></li>
                </ul>
            </div>
            <div className="content_sub_heading">
                <span>Unique Features</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>All neck line options available</span></li>
                    <li><span>Short and long sleeve option</span></li>
                </ul>
            </div>
            <div className="redirect_button">
                <span>Train hard to play hard!</span>
                <Link to="/contact"><button>Get in Touch</button></Link>
            </div>
            <div className="content_heading">
                <span>OFF-PITCH</span>
            </div>
            <hr />
            <div className="content_tagline">
                <div className="bar"></div>
                <span>Fully customised gear to always look your best</span>
            </div>
            <div className="content_text two_content">
                <div className="content">
                    <div className="content_sub_heading">
                        <span>Summer</span>
                        <span className='subheading_tagline'>Tees, Polos and shorts</span>
                    </div>
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No Minimum Order Quanity</span></li>
                        <li><span>Unlimited design options</span></li>
                        <li><span>Any print method available</span></li>
                    </ul>
                </div>
                <div className="content">
                    <div className="content_sub_heading">
                        <span>Winter</span>
                        <span className='subheading_tagline'>Hoodies and tracksuits</span>
                    </div>
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No Minimum Order Quanity</span></li>
                        <li><span>Unlimited design options</span></li>
                        <li><span>Any print method available</span></li>
                    </ul>
                </div>
            </div>
            <div className="redirect_button">
                <span>Fully customised gear</span>
                <Link to="/contact"><button>Get in Touch</button></Link>
            </div>
            <div className="content_heading">
                <span>FOR THE FANS</span>
            </div>
            <hr />
            <div className="content_tagline">
                <div className="bar"></div>
                <span>Show your support</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Sampling prior to production</span></li>
                    <li><span>Quick Turnaround time</span></li>
                    <li><span>Minimum Order Quanity</span></li>
                    <li><span>Unlimited design options</span></li>
                </ul>
            </div>
            <div className="redirect_button">
                <span>Make sure your fans look great too</span>
                <Link to="/contact"><button>Get in Touch</button></Link>
            </div>
            <div className="content_heading">
                <span>CUSTOM BALLS</span>
            </div>
            <hr />
            <div className="content_tagline">
                <div className="bar"></div>
                <span>You can't play without a ball, right?</span>
            </div>
            <div className="content_text">
                <ul>
                    <li><span>Sampling prior to production</span></li>
                    <li><span>Quick Turnaround time</span></li>
                    <li><span>Minimum Order Quanity required for balls</span></li>
                    <li><span>Feature your club logo</span></li>
                    <li><span>All ball sized available</span></li>
                </ul>
            </div>
            <div className="redirect_button">
                <span>Fully customised balls</span>
                <Link to="/contact"><button>Get in Touch</button></Link>
            </div>
        </div>
    </div>
  )
}

export default Soccer
