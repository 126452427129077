import React from 'react'
import './ServicesTagline.css'

const ServicesTagline = () => {
  return (
    <div className='servicesTagline'>
      <div className="servicesContent">
      <h2>Our <span>Services</span></h2>
      <p>Tailoring Perfection: From Custom Sportswear to Bespoke Suits, and Eco-Friendly Fashion to Expert Printing - Your One-Stop Solution for Unique, High-Quality Apparel and Accessories</p>
      </div>
    </div>
  )
}

export default ServicesTagline
