import React from "react";
import './styles/Products.css';
import "../Pages/styles/ProductContent.css"
import { Link } from "react-router-dom";
import Hero from "../Components/Hero/Hero";
import landscape_banner from "../Components/Assets/hoodieBanner.jpg";
import potrait_banner from "../Components/Assets/hoodieBanner_Mobile.jpg";

const Hoodies = () => {

    return(
        <div className="hoodies">
            <Hero landscape_image = {landscape_banner} potrait_image = {potrait_banner}/>
            <div className="productContent">
                <div className="button_container">
                    <button>Get your own custom Hoodie!</button>
                </div>
                <div className="content_heading">
                    <span>Printed</span>
                </div>
                <hr />
                <div className="content_tagline">
                    <div className="bar"></div>
                    <span>Add Your Brand, Club or Company Logos</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Screen Printed, Direct to Garment, Cut, Color, etc</span></li>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No Minimum Order Quanity</span></li>
                        <li><span>Unlimited options with design process</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Your Logo, your Choice!</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
                <div className="content_heading">
                    <span>SUBLIMATED</span>
                </div>
                <hr />
                <div className="content_tagline">
                    <div className="bar"></div>
                    <span>Unlimited colors, unlimited design</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No Minimum Order Quanity</span></li>
                        <li><span>Unlimited options with design process</span></li>
                    </ul>
                </div>
                <div className="content_sub_heading">
                    <span>Good For</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Anyone who loves color and design</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Unlimited design with sublimation</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
                <div className="content_heading">
                    <span>ZIP UP</span>
                </div>
                <hr />
                <div className="content_tagline">
                    <div className="bar"></div>
                    <span>For those who prefer a zip</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Sampling prior to production</span></li>
                        <li><span>Quick Turnaround time</span></li>
                        <li><span>No Minimum Order Quanity</span></li>
                        <li><span>Unlimited options with design process</span></li>
                    </ul>
                </div>
                <div className="content_sub_heading">
                    <span>Good For</span>
                </div>
                <div className="content_text">
                    <ul>
                        <li><span>Brands</span></li>
                        <li><span>Uniforms</span></li>
                        <li><span>Anything else</span></li>
                    </ul>
                </div>
                <div className="redirect_button">
                    <span>Fully customised hoodies</span>
                    <Link to="/contact"><button>Get in Touch</button></Link>
                </div>
            </div>
        </div>
    );
};

export default Hoodies;