import React from 'react'
import "./Location.css"
import proximityLogo from "../Assets/proximity_logo.png";
import skilledLogo from "../Assets/skilled_logo.png";
import logisticsLogo from "../Assets/logistics_logo.png";
import costLogo from "../Assets/cost_logo.png";
import sustainLogo from "../Assets/sustain_logo.png";
import collabLogo from "../Assets/collab_logo.png";


const Location = () => {
  return (
    <div className='location'>
        <div className="locationHeading">
            <span className='locationTitle'>key advantages if <span className="colorChange">our location?</span></span>
        </div>
        <div className="locationContent">
            <div className="card">
                <div className="cardIcon">
                    <img src={proximityLogo} alt="" />
                </div>
                <div className="cardContent">
                    <div className="cardTitle">Proximity to High-Quality Materials</div>
                    <div className="cardText">[City] is home to some of the world's finest fabric suppliers and textile mills. This proximity allows us to source the highest quality materials quickly and efficiently, ensuring that your custom garments are crafted from the best fabrics available.</div>
                </div>
            </div>
            <div className="card">
                <div className="cardIcon">
                    <img src={skilledLogo} alt="" />
                </div>
                <div className="cardContent">
                    <div className="cardTitle">Skilled Workforce</div>
                    <div className="cardText">Our factory is in a region known for its skilled artisans and craftsmen who have honed their skills over generations. This local talent pool enables us to employ experienced tailors and seamstresses dedicated to creating exquisite custom garments.</div>
                </div>
            </div>
            <div className="card">
                <div className="cardIcon">
                    <img src={logisticsLogo} alt="" />
                </div>
                <div className="cardContent">
                    <div className="cardTitle">Efficient Logistics</div>
                    <div className="cardText">Located near major transportation hubs, including international airports and shipping ports, our factory benefits from efficient logistics and reduced shipping times. This strategic position ensures that we can deliver your custom garments promptly, whether you're located domestically or internationally.</div>
                </div>
            </div>
            <div className="card">
                <div className="cardIcon">
                    <img src={costLogo} alt="" />
                </div>
                <div className="cardContent">
                    <div className="cardTitle">Cost-Effective Production</div>
                    <div className="cardText">Operating in 3 countries Australia, India & USA  allows us to leverage cost-effective production without compromising on quality. This balance enables us to offer competitive pricing for our bespoke services, making custom clothing accessible to a wider audience.</div>
                </div>
            </div>
            <div className="card">
                <div className="cardIcon">
                    <img src={sustainLogo} alt="" />
                </div>
                <div className="cardContent">
                    <div className="cardTitle">Sustainability</div>
                    <div className="cardText">Our strategic location supports our commitment to sustainability. By sourcing materials locally and reducing transportation distances, we minimize our carbon footprint. Additionally, our factory adheres to environmentally responsible practices, aligning with our dedication to sustainable fashion.</div>
                </div>
            </div>
            <div className="card">
                <div className="cardIcon">
                    <img src={collabLogo} alt="" />
                </div>
                <div className="cardContent">
                    <div className="cardTitle">Innovation and Collaboration</div>
                    <div className="cardText">Being situated in a dynamic industrial hub fosters innovation and collaboration. We stay at the forefront of the latest trends and technological advancements in the garment industry, ensuring that our custom pieces are modern and cutting-edge.</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Location