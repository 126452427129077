import React from 'react'
import "./CorporateServices.css"

const CorporateServices = () => {
  return (
    <div className='corporateServices'>
      <div className="corporateHeading">
        <span className='corporateTitle'>Corporate <span className='colorChange'>Services</span></span>
        <span className='corporateTagline'>Elevate your brand: professional, custom-designed corporate uniforms and apparel</span>
      </div>
      <div className="corporateContent">
        <div className="corporateCard corporateCard1">
            <div className="corporateCardInfo">
                <span className='cardInfoTitle'>Corporate uniforms</span>
                <span className='cardInfoTagline'>Custom-designed uniforms for businesses and organizations, ensuring a professional and cohesive look for your team.</span>
                <button className='cardBtn'>Place Order</button>
            </div>
        </div>
        <div className="corporateCard corporateCard2">
            <div className="corporateCardInfo">
                <span className='cardInfoTitle'>Branded Apparel</span>
                <span className='cardInfoTagline'>Custom-branded clothing for promotional events, employee attire, and corporate gifts.</span>
                <button className='cardBtn'>Place Order</button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default CorporateServices
