import React from 'react'
import "./Customization.css"
import process1 from "../Assets/process1.png"
import process2 from "../Assets/process2.png"
import process3 from "../Assets/process3.png"
import process4 from "../Assets/process4.png"
import process5 from "../Assets/process5.png"

const Customization = () => {
  return (
    <div className='customization'>
        <div className="customizationHeading">
            <span className='heading'>CUSTOMIZATION PROCESS</span>
            <span className='tagline'>We understand that creating a custom garment can seem daunting, but our process is designed to be seamless and enjoyable. Here is how it works</span>
        </div>
        <div className="customizationContent">
            <div className="card">
                <div className="cardImage">
                    <img src={process1} alt="" />
                </div>
                <div className="cardContent">
                    <span className="cardHeading">Consultation</span>
                    <span className='cardText'>It all starts with you. Share your ideas, inspirations, and requirements with our design team during an initial consultation.</span>
                </div>
            </div>
            <div className="card">
                <div className="cardImage">
                    <img src={process2} alt="" />
                </div>
                <div className="cardContent">
                    <span className="cardHeading">Design</span>
                    <span className='cardText'>Our designers will create sketches and suggest fabrics to bring your vision to life. We collaborate closely with you to finalize the design.</span>
                </div>
            </div>
            <div className="card">
                <div className="cardImage">
                    <img src={process3} alt="" />
                </div>
                <div className="cardContent">
                    <span className="cardHeading">Fitting</span>
                    <span className='cardText'>Precision is key. We take detailed measurements to ensure a perfect fit.</span>
                </div>
            </div>
            <div className="card">
                <div className="cardImage">
                    <img src={process4} alt="" />
                </div>
                <div className="cardContent">
                    <span className="cardHeading">Creation</span>
                    <span className='cardText'>Our artisans get to work, crafting your garment with care and precision.</span>
                </div>
            </div>
            <div className="card">
                <div className="cardImage">
                    <img src={process5} alt="" />
                </div>
                <div className="cardContent">
                    <span className="cardHeading">Final Fitting</span>
                    <span className='cardText'>Once your garment is ready, we invite you for a final fitting to make sure everything is perfect. Any necessary adjustments are made swiftly.</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Customization