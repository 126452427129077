import React from "react";
import './Clients.css';
import client_1 from "../Assets/client_1.jpg"
import client_2 from "../Assets/client_2.jpg"
import client_3 from "../Assets/client_3.jpg"
import client_4 from "../Assets/client_4.jpg"
import client_5 from "../Assets/client_5.jpg"
import client_6 from "../Assets/client_6.jpg"
import client_7 from "../Assets/client_7.jpg"
import client_8 from "../Assets/client_8.jpg"
import client_9 from "../Assets/client_9.jpg"
import client_10 from "../Assets/client_10.jpg"
import { Link } from "react-router-dom";


const Client = () => {
    return(
        <div className="clients">
            <div className="client_content">
                <div className="client_heading">
                    <span>OUR CLIENTS</span>
                </div>
                <hr />
                <div className="client_text">
                    <span>Empowering champions one stride at a time, our clients embody the spirit of determination, perseverance, and excellence. From professional athletes to weekend warriors, our community shares a passion for pushing boundaries and achieving their personal best. Join us on the journey to greatness.</span>
                </div>
                <Link to="/contact"><button>Connect with us</button></Link>
            </div>
            <div className="client_logos">
                <div className="client_row">
                    <img src={client_1} alt="" />
                    <img src={client_2} alt="" />
                    <img src={client_3} alt="" />
                    <img src={client_4} alt="" />
                    <img src={client_5} alt="" />
                </div>
                <div className="client_row">
                    <img src={client_6} alt="" />
                    <img src={client_7} alt="" />
                    <img src={client_8} alt="" />
                    <img src={client_9} alt="" />
                    <img src={client_10} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Client;