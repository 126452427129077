import React from "react";
import {Link} from "react-router-dom"
import './Footer.css';

const Footer = () => {
    return(
        <div className="footer">
            <div className="footer-info">
                <div className="coloumn">
                    <div className="footer-heading">
                        <span className="footer-title">
                            QUICK LINKS
                        </span>
                    </div>
                    <div className="info">
                        <Link to="/"><span>Home</span></Link>
                        <Link to="/services"><span>Services</span></Link>
                        <Link to="/about"><span>About Us</span></Link>
                        <Link to="/contact"><span>Contact Us</span></Link>
                    </div>
                </div>
                <div className="coloumn">
                    <div className="footer-heading">
                        <span className="footer-title">
                            FIND US
                        </span>
                    </div>
                    <div className="icons">
                    <a href="https://www.instagram.com/ozlooms/" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-instagram"></i>
                        </a>
                        <a href="https://www.facebook.com/100064761784350/posts/5208582739174898/" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-facebook"></i>
                        </a>
                        <a href="https://twitter.com/OZLOOMSGLOBAL" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-x-twitter"></i>
                        </a>
                    </div>
                </div>
                <div className="coloumn">
                    <div className="footer-heading">
                        <span className="footer-title">
                            OTHER INFO
                        </span>
                    </div>
                    <div className="info">
                        <Link to="/faq"><span>-Frequently asked questions</span></Link>
                        <Link to="/price"><span>-Price beat guarantee</span></Link>
                        <Link to="/tc"><span>-Terms and conditions</span></Link>
                    </div>
                </div>
                <div className="coloumn">
                    <div className="footer-heading">
                        <span className="footer-title">
                            CONTACT US
                        </span>
                    </div>
                    <div className="address">
                        <span><b>Address</b></span>
                        <span>MARS Sports centre</span>
                        <span>43-63 Lower portrush Rd, Marden</span>
                        <span>South Australia 5070</span> 
                    </div>
                    <div className="phone">
                        <span><b>Phone:</b> (+61) 450590000</span>
                        <span><b>Email: </b> info@ozlooms.com.au</span>
                    </div>
                    <div className="available">
                        <span><b>Available hours</b></span>
                        <span>Monday-Friday 8:00AM-5:00PM ACST </span>
                        <span>But Must email to book an appoinment</span>
                    </div>
                </div>
            </div>
            <div className="copyrights">
                <span>
                    Copyright OZLOOMS 2024. All Rights Reserved
                </span>
            </div>
        </div>
    );
};

export default Footer;